import React from "react"
import PagesLayout from "../components/layout/pages"
import SubmitArticleApplication from "../components/pages/submit-an-article/application"
import ArticleGuidelines from "../components/pages/submit-an-article/guidelines"
import SubmitAnArticleIntro from "../components/pages/submit-an-article/intro"

const SubmitAnArticle = () => {
  return (
    <PagesLayout title="Submit an Article">
      <SubmitAnArticleIntro />
      <ArticleGuidelines />
      <SubmitArticleApplication />
    </PagesLayout>
  )
}

SubmitAnArticle.propTypes = {}

export default SubmitAnArticle
