import React from "react"
import TitleText from "../about-us/title-text"
import Lists from "../../utils/lists"

const ArticleGuidelines = () => {
  const guidelines = [
    (
      <>
        <p className="font-bold">
          Information To Keep In Mind
        </p>
        <p className="mt-4 md:mt-0">
          In order to keep the quality of our content and maintain the trust people have in us, we maintain the privilege to decline articles that don’t align with our goals and convictions.
        </p>
        <p>
          You can’t publish portfolio pieces within this column but you can share a story of your learnings.
        </p>
        <p>
          Posts shouldn’t try to sell anything but can have your portfolio link.
        </p>
      </>
    ),
    (
      <>
        <p className="font-bold">
          Things we may edit before publishing your article
        </p>
        <p className="mt-4 md:mt-0">
          The title and/or the cover image to match our editorial style guide.
        </p>
        <p>
          Typos, formatting style, and minor copy edits or suggestions on more technical reviews.
        </p>
        <p>
          We will remove any affiliate program links or promotional links that are not related to the article.
        </p>
      </>
    )
  ]
  return (
    <>
      <div className="my-24">
        <TitleText title="Guidelines" />
        <p className='md-max:mt-4'>
          This column is mainly for people who want to contribute to DearDesigner, we are looking for articles that have not been published anywhere on in the internet. While we try not to limit you creativity, here are a few things to take note of.
        </p>
        <Lists className="md:!mb-9" listClassName='md-max:list-none md-max:pl-0' items={guidelines} />
      </div>
    </>
  )
}

export default ArticleGuidelines
