import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PageHeading from "../../utils/page-heading"
import { Link } from "gatsby"

const SubmitAnArticleIntro = () => {
  return (
    <>
      <StaticImage
        src="../../../images/submit-an-article.jpg"
        placeholder="blurred"
        className="w-full"
        alt='Submit an Article'
      />

      <PageHeading text="Submit an Article" />
      <p>
        Do you have written works related to the African design industry? Would you like us to feature your article on our platform? Then you’re in luck!
      </p>
      <p>
        We accept one-off submissions from contributors on design-related subjects without any future commitments. If you would prefer to write consistently, then join as a <Link to='/join-as-a-columnist' className='underline'>Columnist.</Link>
      </p>
      <p>
        To ensure your article follows our editorial guidelines for publishing, please read the submission guidelines below.
      </p>
    </>
  )
}

SubmitAnArticleIntro.propTypes = {}

export default SubmitAnArticleIntro
