import React from "react"
import TitleText from "../../about-us/title-text"
import SubmitButton from "../../../utils/button/submit"
import FormSuccess from "../../../utils/form/success"
import FormError from "../../../utils/form/error"
import useForm from "../../../../hooks/useForm"

const SubmitArticleApplication = () => {
  const { onSubmit, isLoading, isError, isSuccess, errorMessage } = useForm();

  return (
    <>
      <TitleText title="Submit Here" />

      <form
        name="submit-an-article"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="form-name" value="submit-an-article" />
        <div className="form-wrapper">
          {/* Full Name */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="fullname"
              placeholder="Full Name"
            />
          </div>

          {/* Email Address */}
          <div className="form-group">
            <input
              required="required"
              type="email"
              name="emailAddress"
              placeholder="Email Address"
            />
          </div>

          {/* LinkedIn Profile */}
          <div className="form-group">
            <input
              type="url"
              required="required"
              name="linkdedinProfile"
              placeholder="LinkedIn Profile"
            />
          </div>

          {/* Give a one paragraph synopsis of the article*/}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="synopsis"
              placeholder="Give a one paragraph synopsis of the article"
            />
          </div>

          {/* Who is the article meant for? */}
          <div className="form-group">
            <input
              type="text"
              required="required"
              name="targetAudience"
              placeholder="Who is the article meant for?"
            />
          </div>

          {/* Article Link (Dropbox Paper or Google Doc) */}
          <div className="form-group">
            <input
              type="url"
              required="required"
              name="articleLink"
              placeholder="Article Link (Dropbox Paper or Google Doc)"
            />
          </div>

          <div className="form-group">
            <SubmitButton disabled={isLoading}/>
          </div>
        </div>
      </form>
      <div>
        {isSuccess && <FormSuccess message="Message has been delivered succcesfully"/>}
        {isError && <FormError message={errorMessage}/>}
      </div>
    </>
  )
}

export default SubmitArticleApplication
